import { AxiosError } from 'axios';
import { FormikHelpers } from 'formik/dist/types';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import RoutePaths from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import { RegistrationFormValues } from '@components/forms/registration/interfaces';

function useRegistration() {
	const history = useHistory();
	const generateRoutePath = useGenerateRoutePath();

	const { startRequest } = useTriggeredRequest(AccountClientRequests.Auth.registration);

	const initialValues: RegistrationFormValues = {
		first_name: '',
		last_name: '',
		email: '',
		password: '',
		password_confirmation: '',
		phone: {
			code: '',
			number: '',
		},
		recaptcha_token: '',
	};

	const onSubmit = useCallback(
		async (values: RegistrationFormValues, formikHelpers: FormikHelpers<RegistrationFormValues>) => {
			try {
				await startRequest({
					first_name: values.first_name,
					last_name: values.last_name,
					email: values.email,
					password: values.password,
					password_confirmation: values.password_confirmation,
					phone: values.phone.code + values.phone.number,
					recaptcha_token: values.recaptcha_token,
				});

				history.push(generateRoutePath(RoutePaths.AUTH.LOGIN));
			} catch (e) {
				const { response } = e as AxiosError<{
					messages: {
						first_name: string[];
						last_name: string[];
						email: string[];
						password: string[];
						password_confirmation: string[];
						phone: string[];
						recaptcha_token: string[];
					};
				}>;

				formikHelpers.setErrors({
					first_name: response?.data?.messages?.first_name?.join(' ') || '',
					last_name: response?.data?.messages?.last_name?.join(' ') || '',
					email: response?.data?.messages?.email?.join(' ') || '',
					password: response?.data?.messages?.password?.join(' ') || '',
					password_confirmation: response?.data?.messages?.password_confirmation?.join(' ') || '',
					phone: {
						code: '',
						number: response?.data?.messages?.phone?.join(' ') || '',
					},
					recaptcha_token: response?.data?.messages?.recaptcha_token?.join(' ') || '',
				});
			}
		},
		[generateRoutePath, history, startRequest],
	);

	return {
		initialValues,
		onSubmit,
	};
}

export default useRegistration;
