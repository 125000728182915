import { useToggle } from '@orbis-systems/orbis-react-toolkit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import CurrentUserInterface from '@api/requests/auth/interfaces/CurrentUserInterface.interface';

import { getCurrentTheme } from '@app/redux/assets/assets.selectors';
import { getCurrentUser, isVerificationAccount } from '@app/redux/auth/auth.selectors';
import { useAppSelector } from '@app/redux/hooks';
import RoutePaths from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import { AppBar, Badge, Box, ButtonBase, Grid, Popper, Stack, Typography } from '@mui/material';

import ContactInfo from '@components/contacts';

import * as environment from '@utils/environment';

import SettingsIcon from 'jsx:@assets/svgs/options.svg';

import UserProfile from '../../../containers/UserProfile';
import { AuthorizedLogo, RootGrid, authorizedLayoutClasses } from './styles';

function AuthorizedPageLayout({ children }: { children: React.ReactNode }) {
	const userProfileRef = React.useRef<HTMLButtonElement>(null);
	const [userProfileOpened, toggleUserProfile] = useToggle(false);

	const currentUser = useAppSelector(getCurrentUser) as CurrentUserInterface;
	const currentTheme = useAppSelector(getCurrentTheme);
	const { needVerifyAccount } = useAppSelector(isVerificationAccount);

	const history = useHistory();
	const generateRoutePath = useGenerateRoutePath();

	if (!currentUser) return <></>;

	return (
		<RootGrid>
			<AppBar className={authorizedLayoutClasses.appBar} position="fixed" color="inherit">
				<Grid display={'flex'} m={0} p={0}>
					<div
						style={{ cursor: 'pointer' }}
						onClick={() => history.push(generateRoutePath(RoutePaths.MAIN.APPLICATIONS))}
					>
						<AuthorizedLogo firm={environment.env.FIRM_ENVIRONMENT} mode={currentTheme} />
					</div>

					<Grid container justifyContent={'flex-end'}>
						<ButtonBase className={authorizedLayoutClasses.account} onClick={() => toggleUserProfile(true)}>
							<Stack direction={'column'}>
								<Typography variant="caption" textAlign={'right'} noWrap sx={{ maxWidth: 130 }}>
									<b>{`${currentUser.first_name} ${currentUser.last_name}`}</b>
								</Typography>

								<Typography variant="caption" textAlign={'right'} color={'primary'} noWrap sx={{ maxWidth: 130 }}>
									{currentUser.email}
								</Typography>
							</Stack>

							{needVerifyAccount ? (
								<Box width={24} height={24} display={'flex'} alignItems={'center'} justifyContent={'center'}>
									<Badge badgeContent="!" color="error" />
								</Box>
							) : (
								<SettingsIcon />
							)}
						</ButtonBase>
					</Grid>
				</Grid>

				<Popper
					className={authorizedLayoutClasses.userProfilePopper}
					anchorEl={userProfileRef.current}
					open={userProfileOpened}
					disablePortal
				>
					<UserProfile currentUser={currentUser} handleCloseUserProfile={() => toggleUserProfile(false)} />
				</Popper>
			</AppBar>

			<Grid pt={8}>{children}</Grid>

			<ContactInfo />
		</RootGrid>
	);
}

export default AuthorizedPageLayout;
