import React from 'react';

import ApplicationDetailsInterface from '@api/requests/applications/interfaces/ApplicationDetailsInterface.interface';

import { getApplicationFormProgress } from '@app/redux/applicationForm/applicationForm.selectors';
import { getApplicationDetails } from '@app/redux/applications/applications.selectors';
import { useAppSelector } from '@app/redux/hooks';

import { Grid } from '@mui/material';

import DetailsSection from '@pages/Applications/containers/ApplicationAccountDetails/components/DetailsSection';
import InfoSection from '@pages/Applications/containers/ApplicationAccountDetails/components/InfoSection';
import WelcomeSection from '@pages/Applications/containers/ApplicationAccountDetails/components/WelcomeSection';

function ApplicationAccountDetails() {
	const applicationDetails = useAppSelector(getApplicationDetails) as ApplicationDetailsInterface;
	const applicationFormProgress = useAppSelector(getApplicationFormProgress);

	if (!applicationDetails) return null;

	return (
		<>
			<Grid item xs={12} sm={6} md={3} order={{ sm: 1, md: 1 }}>
				<InfoSection
					applicationDetails={applicationDetails}
					progressPercentage={
						Math.round(
							(applicationFormProgress.values.total.answered / applicationFormProgress.values.total.required) * 1000,
						) / 10
					}
				/>
			</Grid>

			<Grid item xs={12} sm={12} md={5} order={{ sm: 3, md: 2 }}>
				<DetailsSection applicationDetails={applicationDetails} />
			</Grid>

			<Grid item xs={12} sm={6} md={4} order={{ sm: 2, md: 3 }}>
				<WelcomeSection applicationDetails={applicationDetails} />
			</Grid>
		</>
	);
}

export default ApplicationAccountDetails;
